* {
  font-family: 'Poppins', sans-serif;
}
body {
  margin: 0;
  padding: 0;
  padding-top: 45px;
  letter-spacing: 0.08rem;
}
.brand-name {
  color: white !important;
  text-shadow: 2px 2px 4px black;
}
p {
  line-height: 1.7;
}
.hero-title, .big {
  font-size: 34px;
}
.projects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}

.project-card {
  width: 250px;
  box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 13px;
  padding-bottom: 20px;
  background-color: white;
}

.project-card > img {
  width: 100%;
  height: auto;
  align-self: center;
  border-radius: 5px;
  object-fit: cover;
}

.project-card > h3 {
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px;
  color: #04925c;
}

.project-card > p {
  margin-bottom: 20px;
  font-size: 12px;
  color: #2B283A;
}
.shade {
  height: 56px;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.25);
}
.limit {
  max-width: 540px; 
  text-align: justify;
}
.footer-text {
  color: gray;
  text-align: center;
  font-size: 12px;
  padding: .5rem 0;
}
.link {
  color: rgb(45, 153, 224);
}

.about-logo {  
  object-position: right;
}
.hero-title-accent {
  /* background: #511AEA; */
  background: #04925c;
  color: white;
}
.header-hold {
  max-width: 1300px;
  margin: 0 auto;
}
.about-img-holder {
  width: "400px";
  text-align: center;
}
.hero-btn {
  padding: .5rem 0.8rem;
  border-radius: .4rem;
  background: white;
  border: 2px solid #04925c;
  color: #04925c
}
.hero-btn:hover {
  background: #04925c;
  border: 2px solid #04925c;
  color: white
}
.project-btn {
  padding: .5rem 0.8rem;
  border-radius: .4rem;
  background: #04925c;
  border: 2px solid #04925c;
  color: white
}
.project-btn:hover {
  background: white;
  border: 2px solid;
  color: #04925c
}
@media screen and (max-width: 991px) {
  .hero-logo, .about-logo, .about-img-holder {
    display: none;
  }
  .hero-title-accent {
    background: #04925c;
  }
}

.nav-link:active, .nav-link:focus {
  text-decoration: underline !important;
}

.typing {
  width: 17ch;
  animation: typing 2s steps(36), blink1 .5s step-end alternate;
  white-space: nowrap;
  overflow: hidden;
  /* border-right: 1px solid; */
}

@keyframes typing {
  from {
      width: 0
  }
}
    
@keyframes blink1 {
  50% {
      border-color: transparent
  }
}

@keyframes blink {
  0% {
      opacity: 1;
  }

  50% {
      opacity: 0.1;
  }

  100% {
      opacity: 1;
  }
}

.blinking-text {
  /* text-align: center;
  margin-top: 20%;
  font-size: 24px; */
  /* color: green; */
  animation: blink 5s infinite;
}